<script>
import vue2Dropzone from "vue2-dropzone";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import DatePicker from "vue2-datepicker";
//import NumberInputSpinner from "vue-number-input-spinner";
import Swal from "sweetalert2";
import axios from 'axios';

import {saveAs} from 'file-saver';

import {
  required,
  //email,
  //minLength,
  //sameAs,
  //maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum,
  requiredIf
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Purchase Order registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { vueDropzone: vue2Dropzone, Layout, PageHeader/*DatePicker, NumberInputSpinner*/ },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title: "Purchase Order registration",
      items: [
        {
          text: "Shop",
          href: "/"
        },
        {
          text: "Purchase Orders",
          href: "/Shop/purchaseorders"
        },
        {
          text: "Purchase Order",
          active: true
        }
      ],
      form: {
        id_workorder:     "",
        num_quantity:     "",
        num_total:        "",
        de_material:      "",
        de_description:   "",
        de_notes:         "",
        url_invoice:      "",
        de_status:        "NEW",
      },

      dropzoneOptions: {
        url: window.serverPath+'dist/mvw/controllers/purchaseorders.php',
        thumbnailWidth: 100,
        maxFilesize: 2.5,
        headers: { "My-Awesome-Header": "header value" },
        multipleUpload: false,
        maxFiles: 1,
        autoProcessQueue: false,
        addRemoveLinks: true,
        init: function() {
          this.on("maxfilesexceeded", file => {
            this.removeFile(file);
            Swal.fire('Ooops!!!','<p><b>You can only upload a single template file.</p>','error');
          });
        }
      },

      status:             ["NEW", "IN PROGRESS", "ON HOLD", "COMPLETED"],
      submitted:    false,
      validToken:   false,
    };
  },
  validations: {
    form: {
      id_workorder:     { required },
      num_quantity:     { required },
      de_material:      { required },
      de_description:   { required },
      de_notes:         { required },
      de_status:        { required },

      num_total:        { required: requiredIf(function(form){ return form.de_status != "NEW"}) },
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.getSites();
    
    if( this.$route.params.id ){
      this.getPurchaseOrderByID();
    }

    if( this.user.user.id_role != "1" && this.user.user.id_role != "2" && this.user.user.id_role != "12"){
      this.status = ["NEW", "IN PROGRESS", "COMPLETED"];
    }
  },
  methods: {
    getSites(){
      Swal.fire({
        title: "Loading Sites",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/sites.php',
        {
          params: {
            request: 'getSites',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.sites = response.data;
          Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
        }
      });
    },

    getPurchaseOrderByID(){
      Swal.fire({
        title: "Loading purchase order data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/purchaseorders.php',
        {
          params: {
            request: 'getPurchaseOrderByID',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == 'Invalid token' ){
            this.validToken = false;
          }else{
            app.labor = response.data;

            app.form.id_workorder     = response.data.id_workorder;
            app.form.num_quantity     = response.data.num_quantity;
            app.form.num_total        = response.data.num_total;
            app.form.de_material      = response.data.de_material;
            app.form.de_description   = response.data.de_description;
            app.form.de_notes         = response.data.de_notes;
            app.form.de_status        = response.data.de_status;
            app.form.url_invoice      = response.data.url_invoice;

            Swal.close();
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Labor! Please load this module again.", "error");
        }
      });
    },

    // eslint-disable-next-line no-unused-vars
    saveCustomer(e) {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_purchaseOrder = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving purchase order.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        if(this.$refs.myVueDropzone.dropzone.files.length > 0 ){
          this.$refs.myVueDropzone.processQueue();
        }else{
          axios.post(window.serverPath+'dist/mvw/controllers/purchaseorders.php', {
            request: 'savePurchaseOrder',
            data: this.form,
            token: this.user.token,
          })
          .then(function (response) {
            if( response.data == 'Invalid token' ){
              this.validToken = false;
            }else{
              let action = "";
              if( response.data === "OK" ){
                action = "saved";
              }
              if( response.data === "UPDATED" ){
                action = "updated";
              }

              Swal.fire("Good job!", "Your purchase order is correct "+action+"!", "success");
              app.$router.push({ name: 'purchaseorders' });
            }
          })
          .catch(function (error) {
            Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
          });
        }
      }else{
        console.log(this.$v.form);
      }
    },

    sendingFile(file, xhr, formData){
      let app = this;
      
      file;
      xhr;
      formData;

      //Appending the request, file and data to the formData object to be send all in a single request.
      formData.append('request',  'savePurchaseOrder');
      formData.append('token',     this.user.token);
      formData.append('data',     JSON.stringify(app.form) );
    },
    uploadCompleted(response){
      console.log(response);
      Swal.close();
    },

    downloadTicket(){
      //let app = this;
      axios
      .get(window.serverPath+'dist'+this.form.url_invoice, {responseType: 'blob'})
      .then(response => {
          saveAs(response.data);
      })
      //window.open(window.serverPath+'dist'+this.form.url_invoice);
    },

    openTicket(){
      window.open(window.serverPath+'dist'+this.form.url_invoice);
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="saveCustomer">
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="id_workorder">Workorder</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.id_workorder.$error }"
                      name="id_workorder"
                      id="id_workorder"
                      v-model="form.id_workorder"
                      value=""
                    >
                    <div v-if="submitted && $v.form.id_workorder.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_workorder.required">Labor name is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="num_quantity">Quantity</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_quantity.$error }"
                      name="num_quantity"
                      id="num_quantity"
                      v-model="form.num_quantity"
                      value=""
                    />
                    <div v-if="submitted && $v.form.num_quantity.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_quantity.required">Quantity of material.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="num_total">Total Cost</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.num_total.$error }"
                      name="num_total"
                      id="num_total"
                      v-model="form.num_total"
                      value=""
                    />
                    <div v-if="submitted && $v.form.num_total.$error" class="invalid-feedback">
                      <span v-if="!$v.form.num_total.required">Material price.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="dropzone">Upload the ticket/invoice</label>
                    <vue-dropzone
                      id="dropzone"
                      ref="myVueDropzone"
                      :use-custom-slot="true"
                      :options="dropzoneOptions"
                      :uploadOnDrop="false"
                      @vdropzone-sending="sendingFile"
                      @vdropzone-complete="uploadCompleted"
                    >
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>Drop ticket into this section.</h4>
                      </div>
                    </vue-dropzone>
                    
                    <button v-if="form.url_invoice != ''" type="button" class="mt-3 btn btn-sm btn-outline-dark" @click="downloadTicket">
                      Download ticket
                      <b-badge variant="dark" class="ml-1"><i class="fas fa-download"></i></b-badge>
                    </button>
                    <button v-if="form.url_invoice != ''" type="button" class="mt-3 ml-3 btn btn-sm btn-outline-primary" @click="openTicket">
                      View/open ticket
                      <b-badge variant="primary" class="ml-1"><i class="far fa-eye"></i></b-badge>
                    </button>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="de_material">Material</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_material.$error }"
                      name="de_material"
                      id="de_material"
                      v-model="form.de_material"
                      value=""
                    >
                    <div v-if="submitted && $v.form.de_material.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_material.required">Please enter the material needed, this is required.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="de_description">Material description (color, function, electricity, etc.)</label>
                    <textarea
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_description.$error }"
                      name="de_description"
                      id="de_description"
                      v-model="form.de_description"
                      value=""
                      rows="5"
                    ></textarea>
                    <div v-if="submitted && $v.form.de_description.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_description.required">Please enter the material description.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="de_notes">Notes (justification, comments)</label>
                    <textarea
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_notes.$error }"
                      name="de_notes"
                      id="de_notes"
                      v-model="form.de_notes"
                      value=""
                      rows="5"
                    ></textarea>
                    <div v-if="submitted && $v.form.de_notes.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_notes.required">Please enter a note or justification for this purchase order.</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="de_status">Status</label>
                    <select
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.de_status.$error }"
                      name="de_status"
                      id="de_status"
                      v-model="form.de_status"
                      @change="onHold"
                    >
                      <option v-for="(state, index) in status" :key="index" :value="state">{{state}}</option>
                    </select>
                    <div v-if="submitted && $v.form.de_status.$error" class="invalid-feedback">
                      <span v-if="!$v.form.de_status.required">Status required.</span>
                    </div>
                  </div>

                  <div v-if="form.de_status == 'ON HOLD'" class="form-group">
                    <label for="de_onHoldJustification">On hold justification</label>
                    <textarea name="textarea" v-model="form.de_onHoldJustification" rows="5" class="form-control"></textarea>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">SAVE PURCHASE ORDER</button>
                    <router-link to="/shop/labors" class="ml-3">
                      <button class="btn btn-danger" type="reset">CANCEL </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
  .dropzone{ padding:0px !important; }
</style>